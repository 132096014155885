import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { currencyFormat, renderCronStatus } from '../../../helpers';

const CardStructure = ({ title, icon, RewData }) => (
	<Card className="">
		<CardBody className="pb-0">
			<div className="h4 text-muted text-right pull-right">{icon}</div>
			<div className="text-muted text-uppercase font-weight-bold mb-3"> {title}</div>
			<hr />
			<div className="row  mb-3">{RewData}</div>
		</CardBody>
	</Card>
);

const CardRowData = ({ name, value }) => (
	<React.Fragment>
		<div className="col-sm-12 mb-2">
			{' '}
			{name}
			<span className="pull-right"> {value} </span>{' '}
		</div>
	</React.Fragment>
);

const CardRowDataThreeCols = ({ name, value1, value2, value3, name_title, title1, title2, title3 }) => (
	<React.Fragment>
		<Col xs="2" className="mb-2">
			{name_title ? <b>{name_title} </b> : name}
		</Col>
		<Col xs="10" className="mb-2">
			<Row>

				<Col xs="4" className="text-right mb-2">
					{title1 ? <b>{title1} </b> : value1}
				</Col>
				<Col xs="4" className="text-right mb-2">
					{title2 ? <b>{title2} </b> : value2}
				</Col>
				<Col xs="4" className="text-right mb-2">
					{title3 ? <b>{title3} </b> : value3}
				</Col>
			</Row>
		</Col>
	</React.Fragment>
);

const CardRowDataFourCols = ({ name, value1, value2, value3, name_title, title1, title2, title3, value4, title4 }) => (
	<React.Fragment>
		<Col xs="2" className="mb-2">
			{name_title ? <b>{name_title} </b> : name}
		</Col>
		<Col xs="2" className="text-center mb-2">
			{title1 ? <b>{title1} </b> : value1}
		</Col>
		<Col xs="3" className="text-center mb-2">
			{title2 ? <b>{title2} </b> : value2}
		</Col>
		<Col xs="2" className="text-center mb-2">
			{title3 ? <b>{title3} </b> : value3}
		</Col>
		<Col xs="3" className="text-center mb-2">
			{title4 ? <b>{title4} </b> : value4}
		</Col>
	</React.Fragment>
);


const CardRowDataTwoCols = ({ name, value1, value2, name_title, title1, title2 }) => (
	<React.Fragment>
		<Col xs="4" className="mb-2">
			{name_title ? <b>{name_title} </b> : name}
		</Col>
		<Col xs="4" className="text-center mb-2">
			{title1 ? <b>{title1} </b> : value1}
		</Col>
		<Col xs="4" className="text-center mb-2">
			{title2 ? <b>{title2} </b> : value2}
		</Col>
	</React.Fragment>
);

const CardRowDataThreeColsRightText = ({ name, value1, value2, value3, value4, name_title, title1, title2, title3, title4 }) => (
	<React.Fragment>
		<Col xs="3" >
			{name_title ? <b>{name_title} </b> : name}

			<hr className='m-1' />
		</Col>
		<Col xs="2" className="text-right mb-2">
			{title1 ? <b>{title1} </b> : value1}
			<hr className='m-1' />
		</Col>
		<Col xs="2" className="text-right mb-2">
			{title2 ? <b>{title2} </b> : value2}
			<hr className='m-1' />
		</Col>
		<Col xs="2" className="text-right mb-2">
			{title3 ? <b>{title3} </b> : value3}
			<hr className='m-1' />
		</Col>
		<Col xs="2" className="text-right mb-2">
			{title4 ? <b>{title4} </b> : value4}
			<hr className='m-1' />
		</Col>
	</React.Fragment>
);

const CardRowDataCols10 = ({ name,
	value1, value2, value3, value4, value5, name_title,
	title1, title2, title3, title4, title5 }) => (
	<React.Fragment>
		<Col xs="2" className="mb-2">
			{name_title ? <b>{name_title} </b> : name}
		</Col>
		<Col xs="2" className="text-center mb-2">
			{title1 ? <b>{title1} </b> : value1}
		</Col>
		<Col xs="2" className="text-center mb-2">
			{title2 ? <b>{title2} </b> : value2}
		</Col>
		<Col xs="2" className="text-center mb-2">
			{title3 ? <b>{title3} </b> : value3}
		</Col>
		<Col xs="2" className="text-center mb-2">
			{title4 ? <b>{title4} </b> : value4}
		</Col>
		<Col xs="2" className="text-center mb-2">
			{title5 ? <b>{title5} </b> : value5}
		</Col>
	</React.Fragment>
);
const CardRowDataColsRightText10 = ({ name,
	value1, value2, value3, value4, value5, name_title,
	title1, title2, title3, title4, title5 }) => (
	<React.Fragment>
		<Col xs="2" className="mb-2">
			{name_title ? <b>{name_title} </b> : name}
		</Col>
		<Col xs="2" className="text-center mb-2">
			{title1 ? <b>{title1} </b> : value1}
		</Col>
		<Col xs="2" className="text-center mb-2">
			{title2 ? <b>{title2} </b> : value2}
		</Col>
		<Col xs="2" className="text-center mb-2">
			{title3 ? <b>{title3} </b> : value3}
		</Col>
		<Col xs="2" className="text-center mb-2">
			{title4 ? <b>{title4} </b> : value4}
		</Col>
		<Col xs="2" className="text-center mb-2">
			{title5 ? <b>{title5} </b> : value5}
		</Col>
	</React.Fragment>
);


export const DashboardCard1 = ({ title, data1, data2, data3, title1, title2, title3, icon, loading }) => (
	<CardStructure
		title={title}
		icon={icon}
		RewData={
			<React.Fragment>
				<CardRowDataThreeCols name_title={''} title1={title1} title2={title2} title3={title3} />
				<CardRowDataThreeCols
					name={'Today'}
					value1={loading || !data1 ? <i className="fa fa-spin fa-spinner" /> : data1.today}
					value2={loading || !data2 ? <i className="fa fa-spin fa-spinner" /> : data2.today}
					value3={loading || !data3 ? <i className="fa fa-spin fa-spinner" /> : data3.today}
				/>
				<CardRowDataThreeCols
					name={'Week'}
					value1={loading || !data1 ? <i className="fa fa-spin fa-spinner" /> : data1.week}
					value2={loading || !data2 ? <i className="fa fa-spin fa-spinner" /> : data2.week}
					value3={loading || !data3 ? <i className="fa fa-spin fa-spinner" /> : data3.week}
				/>

				<CardRowDataThreeCols
					name={'Month'}
					value1={loading || !data1 ? <i className="fa fa-spin fa-spinner" /> : data1.month}
					value2={loading || !data2 ? <i className="fa fa-spin fa-spinner" /> : data2.month}
					value3={loading || !data3 ? <i className="fa fa-spin fa-spinner" /> : data3.month}
				/>

				<CardRowDataThreeCols
					name={'Year'}
					value1={loading || !data1 ? <i className="fa fa-spin fa-spinner" /> : data1.year}
					value2={loading || !data2 ? <i className="fa fa-spin fa-spinner" /> : data2.year}
					value3={loading || !data3 ? <i className="fa fa-spin fa-spinner" /> : data3.year}
				/>

				<CardRowDataThreeCols
					name={'Lifetime'}
					value1={loading || !data1 ? <i className="fa fa-spin fa-spinner" /> : data1.lifetime}
					value2={loading || !data2 ? <i className="fa fa-spin fa-spinner" /> : data2.lifetime}
					value3={loading || !data3 ? <i className="fa fa-spin fa-spinner" /> : data3.lifetime}
				/>
			</React.Fragment>
		}
	/>
);

const CurrencyAndValue = ({ cueency, value }) => <React.Fragment> {currencyFormat(cueency, true)} <b style={{ minWidth: "40px", display: 'inline-block' }}> [{value}]  </b></React.Fragment>


export const DashboardCard1CurrencyFormat = ({ title, data1, data2, data3, registration_count, recharge_count, title1, title2, title3, title4, value4, icon, loading }) => (
	<CardStructure
		title={title}
		icon={icon}
		RewData={
			<React.Fragment>
				<CardRowDataThreeCols name_title={''} title1={title1} title2={title2} title3={title3} title4={title4} />
				<CardRowDataThreeCols
					name={'Today'}
					value1={loading || !data2 ? <i className="fa fa-spin fa-spinner" /> : <CurrencyAndValue cueency={data2.today} value={registration_count.today} />}
					value2={loading || !data3 ? <i className="fa fa-spin fa-spinner" /> : <CurrencyAndValue cueency={data3.today} value={recharge_count.today} />}
					value3={loading || !data3 ? <i className="fa fa-spin fa-spinner" /> : <CurrencyAndValue cueency={Number(data2.today) + Number(data3.today)} value={Number(registration_count.today) + Number(recharge_count.today)} />}
				/>
				<CardRowDataThreeCols
					name={'Week'}
					value1={loading || !data2 ? <i className="fa fa-spin fa-spinner" /> : <CurrencyAndValue cueency={data2.week} value={registration_count.week} />}
					value2={loading || !data3 ? <i className="fa fa-spin fa-spinner" /> : <CurrencyAndValue cueency={data3.week} value={recharge_count.week} />}
					value3={loading || !data3 ? <i className="fa fa-spin fa-spinner" /> : <CurrencyAndValue cueency={Number(data2.week) + Number(data3.week)} value={Number(registration_count.week) + Number(recharge_count.week)} />}
				/>

				<CardRowDataThreeCols
					name={'Month'}
					value1={loading || !data2 ? <i className="fa fa-spin fa-spinner" /> : <CurrencyAndValue cueency={data2.month} value={registration_count.month} />}
					value2={loading || !data3 ? <i className="fa fa-spin fa-spinner" /> : <CurrencyAndValue cueency={data3.month} value={recharge_count.month} />}
					value3={loading || !data3 ? <i className="fa fa-spin fa-spinner" /> : <CurrencyAndValue cueency={Number(data2.month) + Number(data3.month)} value={Number(registration_count.month) + Number(recharge_count.month)} />}
				/>

				<CardRowDataThreeCols
					name={'Year'}
					value1={loading || !data2 ? <i className="fa fa-spin fa-spinner" /> : <CurrencyAndValue cueency={data2.year} value={registration_count.year} />}
					value2={loading || !data3 ? <i className="fa fa-spin fa-spinner" /> : <CurrencyAndValue cueency={data3.year} value={recharge_count.year} />}
					value3={loading || !data3 ? <i className="fa fa-spin fa-spinner" /> : <CurrencyAndValue cueency={Number(data2.year) + Number(data3.year)} value={Number(registration_count.year) + Number(recharge_count.year)} />}
				/>

				<CardRowDataThreeCols
					name={'Lifetime'}
					value1={loading || !data2 ? <i className="fa fa-spin fa-spinner" /> : <CurrencyAndValue cueency={data2.lifetime} value={registration_count.lifetime} />}
					value2={loading || !data3 ? <i className="fa fa-spin fa-spinner" /> : <CurrencyAndValue cueency={data3.lifetime} value={recharge_count.lifetime} />}
					value3={loading || !data3 ? <i className="fa fa-spin fa-spinner" /> : <CurrencyAndValue cueency={Number(data2.lifetime) + Number(data3.lifetime)} value={Number(registration_count.lifetime) + Number(recharge_count.lifetime)} />}
				/>
			</React.Fragment>
		}
	/>
);


export const DashboardCardCol2 = ({ title, data1, data2, title1, title2, icon, loading }) => (
	<CardStructure
		title={title}
		icon={icon}
		RewData={
			<React.Fragment>
				<CardRowDataTwoCols name_title={''} title1={title1} title2={title2} />
				<CardRowDataTwoCols
					name={'Today'}
					value1={loading || !data1 ? <i className="fa fa-spin fa-spinner" /> : data1.today}
					value2={loading || !data2 ? <i className="fa fa-spin fa-spinner" /> : data2.today}
				/>
				<CardRowDataTwoCols
					name={'Week'}
					value1={loading || !data1 ? <i className="fa fa-spin fa-spinner" /> : data1.week}
					value2={loading || !data2 ? <i className="fa fa-spin fa-spinner" /> : data2.week}
				/>

				<CardRowDataTwoCols
					name={'Month'}
					value1={loading || !data1 ? <i className="fa fa-spin fa-spinner" /> : data1.month}
					value2={loading || !data2 ? <i className="fa fa-spin fa-spinner" /> : data2.month}
				/>

				<CardRowDataTwoCols
					name={'Year'}
					value1={loading || !data1 ? <i className="fa fa-spin fa-spinner" /> : data1.year}
					value2={loading || !data2 ? <i className="fa fa-spin fa-spinner" /> : data2.year}
				/>

				<CardRowDataTwoCols
					name={'Lifetime'}
					value1={loading || !data1 ? <i className="fa fa-spin fa-spinner" /> : data1.lifetime}
					value2={loading || !data2 ? <i className="fa fa-spin fa-spinner" /> : data2.lifetime}
				/>
			</React.Fragment>
		}
	/>
);


export const CreditStatus = ({ title, data, icon, loading }) => (
	<CardStructure
		title={title}
		data={data}
		icon={icon}
		RewData={
			<React.Fragment>
				<CardRowData
					name={'Pending'}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : data.pending}
				/>
				<CardRowData
					name={'Current'}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : data.current}
				/>
				<CardRowData
					name={'Overdue'}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : data.overdue}
				/>
				<CardRowData
					name={'Bad Debt'}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : data.bad_debt}
				/>
				<CardRowData
					name={'Issue'}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : data.issued}
				/>
				<CardRowData
					name={'In Court'}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : data.in_court}
				/>
				<CardRowData
					name={'Complete'}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : data.completed}
				/>
			</React.Fragment>
		}
	/>
);

export const LeadVsRegister = ({ title, data, icon, loading }) => (
	<CardStructure
		title={title}
		data={data}
		icon={icon}
		RewData={
			<React.Fragment>
				<CardRowData
					name={'Lead'}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : data.leads_count}
				/>
				<CardRowData
					name={'Registered'}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : data.registered}
				/>
				<CardRowData
					name={'Registered  Ratio'}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : data.percentage}
				/>
				<CardRowData
					name={'Earning '}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : currencyFormat(data.earning, true)}
				/>
				<CardRowData
					name={' '}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : <Link to='/recent-registrations?registration-analysis' >View More</Link>}
				/>
			</React.Fragment>
		}
	/>
);
export const Searches = ({ title, data, icon, loading }) => (
	<CardStructure
		title={title}
		data={data}
		icon={icon}
		RewData={
			<React.Fragment>
				<CardRowData
					name={'Today'}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : data.today}
				/>
				<CardRowData
					name={'Week'}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : data.week}
				/>
				<CardRowData
					name={'Month'}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : data.month}
				/>
				
				<CardRowData
					name={'Year'}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : data.year}
				/>

				<CardRowData
					name={'Lifetime'}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : data.lifetime}
				/>
			</React.Fragment>
		}
	/>
);

export const SearchStatus = ({ title, data, icon, loading }) => (
	<CardStructure
		title={title}
		data={data}
		icon={icon}
		RewData={
			<React.Fragment>
				<CardRowData
					name={'Home Page Search'}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : data.homepage}
				/>
				<CardRowData
					name={'Company Search'}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : data.company}
				/>
				<CardRowData
					name={'Individual Search'}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : data.individual}
				/>
			</React.Fragment>
		}
	/>
);

export const ReputeinfoStatisticsStatus = ({ title, data, icon, loading }) => (
	<CardStructure
		title={title}
		data={data}
		icon={icon}
		RewData={
			<React.Fragment>
				<CardRowData
					name={'Individuals Searched'}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : data.individual_searched}
				/>
				<CardRowData
					name={'Companies Searched'}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : data.companies_searched}
				/>
				<CardRowData
					name={'Individuals Registered'}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : data.individuals_registered}
				/>
				<CardRowData
					name={'Companies Registered'}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : data.companies_registered}
				/>
				<CardRowData
					name={'Transactions Accepted'}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : data.transaction_accepted}
				/>
				<CardRowData
					name={'Transactions Completed'}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : data.transaction_completed}
				/>
				<CardRowData
					name={'Defaulter companies'}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : data.defaulters_listed}
				/>
				<CardRowData
					name={'Credit Given'}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : "₹ " + currencyFormat(data.credit_given)}
				/>
				<CardRowData
					name={'Unpaid Credit'}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : "₹ " + currencyFormat(data.unpaid_credit)}
				/>
				<CardRowData
					name={'Paid Credit'}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : "₹ " + currencyFormat(data.paid_credit)}
				/>
			</React.Fragment>
		}
	/>
);

export const ReputeinfoUserAnalysis = ({ title, data, icon, loading }) => (

	<CardStructure
		title={title}
		data={data}
		icon={icon}
		RewData={
			<React.Fragment>
				<CardRowData
					name={'Registered Company'}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : data.registered_company}
				/>
				<CardRowData
					name={'Unregistered Company'}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : data.unregistered_company}
				/>
				<CardRowData
					name={'Verified Company With Aadhar'}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : data.verified_company_with_aadhar}
				/>
				<CardRowData
					name={'Send Bill Transactions Register'}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : data.send_bill_transactions_reg}
				/>
				<CardRowData
					name={'Send Bill Transaction Unregister'}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : data.send_bill_transactions_un}
				/>
				<CardRowData
					name={'Send Bill Transaction Total'}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : data.send_bill_transactions_total}
				/>
			</React.Fragment>
		}
	/>
);



export const TicketStatus = ({ title, data, icon, loading }) => (
	<CardStructure
		title={title}
		data={data}
		icon={icon}
		RewData={
			<React.Fragment>
				<CardRowData
					name={'New'}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : data.new}
				/>
				<CardRowData
					name={'Reopen'}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : data.reopen}
				/>
				<CardRowData
					name={'In Progress'}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : data.inprogress}
				/>
				<CardRowData
					name={'Resolved'}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : data.resolved}
				/>
				<CardRowData
					name={'Close'}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : data.close}
				/>
			</React.Fragment>
		}
	/>
);

export const GridLineData = ({ title, data, icon, loading }) => (
	<CardStructure
		title={title}
		data={data}
		icon={icon}
		RewData={
			<React.Fragment>
				<CardRowData
					name={'gst'}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : data.gst ? data.gst : '-'}
				/>
				<CardRowData
					name={'pan'}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : data.pan ? data.pan : '-'}
				/>
				<CardRowData
					name={'aadhar'}
					value={loading || !data ? <i className="fa fa-spin fa-spinner" /> : data.aadhar ? data.aadhar : '-'}
				/>
			</React.Fragment>
		}
	/>
);

export const CronStatus = (props) => {
	let { title, data, icon, loading } = props;

	return (
		<CardStructure
			title={title}
			data={data}
			icon={icon}
			RewData={
				<React.Fragment>
					<Col xs="4" className="">
						<b className="text-muted">Cron Name</b>
					</Col>
					<Col xs="2" className="">
						<b className="text-muted">Total</b>
					</Col>
					<Col xs="2" className="">
						<b className="text-muted">Status</b>
					</Col>
					<Col xs="4" className="">
						<b className="text-muted">Last Execution Time</b>
					</Col>
					<hr />

					<Col xs="4">Bad Debt Transactions Challange</Col>
					<Col xs="2" className="">
						{loading || !data ? (
							<i className="fa fa-spin fa-spinner" />
						) : (
							data.baddebt_transaction_challenge.total_transactions_performed
						)}
					</Col>
					<Col xs="2" className="">
						{loading || !data ? (
							<i className="fa fa-spin fa-spinner" />
						) : (
							renderCronStatus(data.baddebt_transaction_challenge.status)
						)}
					</Col>
					<Col xs="4" className="mb-2">
						{loading || !data ? (
							<i className="fa fa-spin fa-spinner" />
						) : (
							moment(data.baddebt_transaction_challenge.executed_time).format('DD-MM-YYYY hh:mm A')
						)}
					</Col>

					<Col xs="4" className="">
						Transactions Overdue & Reminder
					</Col>
					<Col xs="2" className="">
						{loading || !data ? (
							<i className="fa fa-spin fa-spinner" />
						) : (
							data.transaction_overdue.total_transactions_performed
						)}
					</Col>
					<Col xs="2" className="">
						{loading || !data ? (
							<i className="fa fa-spin fa-spinner" />
						) : (
							renderCronStatus(data.transaction_overdue.status)
						)}
					</Col>
					<Col xs="4" className="mb-2">
						{loading || !data ? (
							<i className="fa fa-spin fa-spinner" />
						) : (
							moment(data.transaction_overdue.executed_time).format('DD-MM-YYYY hh:mm A')
						)}
					</Col>

					<Col xs="4" className="">
						Day Wise Milestone Data
					</Col>
					<Col xs="2" className="">
						NA
					</Col>
					<Col xs="2" className="">
						{loading || !data ? (
							<i className="fa fa-spin fa-spinner" />
						) : (
							renderCronStatus(data.day_wise_milestone_cron_data.status)
						)}
					</Col>
					<Col xs="4" className="mb-2">
						{loading || !data ? (
							<i className="fa fa-spin fa-spinner" />
						) : (
							moment(data.day_wise_milestone_cron_data.executed_time).format('DD-MM-YYYY hh:mm A (dddd)')
						)}
					</Col>

					<Col xs="4" className="">
						Notify Remaining Amounts
					</Col>
					<Col xs="2" className="">
						{loading || !data ? (
							<i className="fa fa-spin fa-spinner" />
						) : (
							data.notify_remaining_amounts.total_transactions_performed
						)}
					</Col>
					<Col xs="2" className="">
						{loading || !data ? (
							<i className="fa fa-spin fa-spinner" />
						) : (
							renderCronStatus(data.notify_remaining_amounts.status)
						)}
					</Col>
					<Col xs="4" className="">
						{loading || !data ? (
							<i className="fa fa-spin fa-spinner" />
						) : (
							moment(data.notify_remaining_amounts.executed_time).format('DD-MM-YYYY hh:mm A')
						)}
					</Col>
				</React.Fragment>
			}
		/>
	);
};


export const SalesStatusOverview = (props) => {
	let { title, data, icon, loading } = props;
	let dataArray = []
	if (!loading && data) {

		for (var key in data) {
			if (data.hasOwnProperty(key)) {
				dataArray.push(data[key]);
			}
		}
	}
	return (
		<CardStructure
			title={title}
			data={data}
			icon={icon}
			RewData={
				<React.Fragment>
					<Col xs="8" className="">
						<b className="text-muted">Sales Status At</b>
					</Col>
					<Col xs="4" className="">
						<b className="text-muted">Counts</b>
					</Col>
					<hr />
					{!loading && dataArray && dataArray.map(function (eachItem, idx) {
						return <SalesStatusOverviewRow key={idx} title={eachItem.title} count={eachItem.count} />
					})}


				</React.Fragment>
			}
		/>
	);
};


function renderRowData(data, isDetails) {

	let newObj = {};
	let new_object_keys = [];
	if (data && typeof data === "object" && data.length) {
		data.map((eachObject) => {
			new_object_keys.push(eachObject.created_at);
			for (var each_key in eachObject) {
				if (each_key === "created_at") {
					continue;
				}
				if (newObj.hasOwnProperty(each_key)) {

				} else {
					newObj[each_key] = {};
					newObj[each_key]["name"] = each_key;
				}

				if (each_key === "Credit given" || each_key === "Paid credit" || each_key === "Unpaid credit") {
					newObj[each_key][eachObject.created_at] = "₹" + currencyFormat(eachObject[each_key]);

				} else {

					newObj[each_key][eachObject.created_at] = eachObject[each_key];
				}
			}
		})
	}

	// for(var key in newObj){
	// 	let each =  newObj[key]
	// 	{<CardRowDataThreeCols
	// 	name={key}
	// 	value1={each.hasOwnProperty( new_object_keys[0]) ? each[new_object_keys[0]] : "-" }
	// 	value2={each.hasOwnProperty[ new_object_keys[1]]? each[new_object_keys[1]] : "-"}
	// 	value3={each.hasOwnProperty[ new_object_keys[2]]? each[new_object_keys[2] ]: "-"}
	// />}
	// }
	// const objectArray = Object.entries(numbers);
	const objectArray = Object.values(newObj);
	return <React.Fragment>
		{isDetails ?
			<CardRowDataColsRightText10
				name_title={''}
				title1={new_object_keys[0] ? new_object_keys[0] : "-"}
				title2={new_object_keys[1] ? new_object_keys[1] : "-"}
				title3={new_object_keys[2] ? new_object_keys[2] : "-"}
				title4={new_object_keys[3] ? new_object_keys[3] : "-"}
				title5={new_object_keys[4] ? new_object_keys[4] : "-"}
			/> : <CardRowDataThreeColsRightText
				name_title={''}
				title1={new_object_keys[0] ? new_object_keys[0] : "-"}
				title2={new_object_keys[1] ? new_object_keys[1] : "-"}
				title3={new_object_keys[2] ? new_object_keys[2] : "-"}
				title4={new_object_keys[3] ? new_object_keys[3] : "-"}
			/>
		}

		{objectArray.map((each, key) => {

			return isDetails ? <CardRowDataCols10
				name={each.name}
				value1={each.hasOwnProperty(new_object_keys[0]) ? each[new_object_keys[0]] : "-"}
				value2={each.hasOwnProperty(new_object_keys[1]) ? each[new_object_keys[1]] : "-"}
				value3={each.hasOwnProperty(new_object_keys[2]) ? each[new_object_keys[2]] : "-"}
				value4={each.hasOwnProperty(new_object_keys[4]) ? each[new_object_keys[4]] : "-"}
				value5={each.hasOwnProperty(new_object_keys[5]) ? each[new_object_keys[5]] : "-"}
			/> : <CardRowDataThreeColsRightText
				name={each.name}
				value1={each.hasOwnProperty(new_object_keys[0]) ? each[new_object_keys[0]] : "-"}
				value2={each.hasOwnProperty(new_object_keys[1]) ? each[new_object_keys[1]] : "-"}
				value3={each.hasOwnProperty(new_object_keys[2]) ? each[new_object_keys[2]] : "-"}
				value4={each.hasOwnProperty(new_object_keys[3]) ? each[new_object_keys[3]] : "-"}
			/>
		})}
	</React.Fragment>


}

export const MilestoneData = ({ title, data, icon }) => {


	return <CardStructure
		title={title}
		icon={icon}
		RewData={
			<React.Fragment>

				{renderRowData(data)}

			</React.Fragment>
		}
	/>
}

export const MilestoneData2 = ({ title, data, icon }) => {

	return <React.Fragment>

		<div className="row  mb-3">		{renderRowData(data, true)}</div>

	</React.Fragment>

}

export const SalesStatusOverviewRow = ({ title, count }) => {
	return <React.Fragment>
		<Col xs="8" className="mb-2">
			{title}
		</Col>
		<Col xs="4" className="mb-2">
			{count}
		</Col>
	</React.Fragment>
}