import React from 'react'

export default function Address({ address }) {
    if(!address) {
        return "Address Not Available"
    }
    return (<React.Fragment>
        {address.line1 ? <React.Fragment>{address.line1},<br /></React.Fragment> : false}
        {address.line2 ? <React.Fragment>{address.line2},<br /></React.Fragment> : false}
        {address.city ? <React.Fragment>{address.city},</React.Fragment> : false}
        {address.taluka ? <React.Fragment>{address.taluka},  <br /></React.Fragment> : false}
        {address.pincode ? <React.Fragment>{address.pincode}, {address.district}, {address.state},  <br /></React.Fragment> : false}

    </React.Fragment>
    )
}
