import React, { Component } from 'react'
import { Translate } from "react-localize-redux"
import { connect } from 'react-redux'
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import { TableLoader } from '../../../../components/Loaders'
import { getStatusIcon } from '../../../../helpers'
import { verificationActions } from '../../../actions'
import { Documents } from './Comman/Documents'

import { Link } from 'react-router-dom'
import swal from 'sweetalert'
import Address from '../../../../helpers/Address'
import { documentStatus, verificationConstants } from '../../../constants'
import UpdateGst from './Comman/UpdateGst'
import { VerifyDoucment } from './Comman/VerifyDoucment'

const TABS = {
    VERIFIED: "verified",
    NOT_VERIFIED: "not_verified",
    ALL: "all",
}
const PanCardCompanyType = ({ data }) => {
    let panCardType;
    switch (data) {
        case 'P':
            panCardType = 'Person'
            break;
        case "C":
            console.log(data)
            panCardType = 'Company'
            break;
        case "H":
            panCardType = ' HUF (Hindu Undivided Family) '
            break;
        case "F":
            panCardType = 'Firm '
            break;
        case "A":
            panCardType = 'Association of Persons (AOP) '
            break;
        case "T":
            panCardType = 'AOP (Trust) '
            break;
        case "B":
            panCardType = 'Body of Individuals (BOI) '
            break;
        case "L":
            panCardType = 'Local Authority '
            break;
        case "J":
            panCardType = 'Artificial Juridical Person '
            break;
        case "G":
            panCardType = 'Government '
            break;

        default: 
            break;
    }
    return <span>{panCardType}</span>
}


const RenderStatus = ({ status, text }) => {
    let statusClassName;
    let badge;
    switch (status) {
        case true:
            statusClassName = "fa fa-check-circle"; 
            badge = "badge-success"; break;

        case false:
            statusClassName = "fa fa-times-circle "; 
            badge = "badge-danger"; break;


    }
    return <label className={'badge '+badge}><i className={statusClassName}></i> {text}</label>
}

const EachRowData = ({ title, data, apidata, status, action, aadharApidata, PANapiData, statusAction }) => {
    return <tr>
        <td style={{ width: '10%', border: "1px solid #909090" }}><b>{title}</b></td>
        <td style={{ width: '15%', border: "1px solid #909090" }}> <b> {data ? data : "Not Available"} {status} </b></td>
        <td style={{ width: '15%', border: "1px solid #909090" }}><b>{aadharApidata}</b></td>
        <td style={{ width: '20%', border: "1px solid #909090" }}><b>{apidata ? apidata : "-"} </b></td>
        <td style={{ width: '20%', border: "1px solid #909090" }}><b>{PANapiData ? PANapiData : "-"}</b> </td>
        <td style={{ width: '10%', border: "1px solid #909090" }} ><b>{action}</b></td>
        {status ? <td style={{ width: '10%', border: "1px solid #909090" }}>
            {typeof aadharApidata === 'string' && aadharApidata.toUpperCase().includes(data) ?
                <React.Fragment>
                    <RenderStatus text="AADHAR" status={typeof aadharApidata === 'string' ? aadharApidata.toUpperCase().includes(data) : false} />
                </React.Fragment>
                : false}
            
            &nbsp;&nbsp;

            {typeof apidata === 'string' && apidata.toUpperCase().includes(data) ?
                <React.Fragment>
                    <RenderStatus text="GST" status={typeof apidata === 'string' ? apidata.toUpperCase().includes(data) : false} />
                </React.Fragment>
                : false}
            
            &nbsp;&nbsp;

            {typeof  PANapiData === 'string' && typeof data === 'string' && PANapiData.toUpperCase().includes(data.toUpperCase()) ?
                <React.Fragment>
                    <RenderStatus text="PAN" status={typeof PANapiData === 'string' ? PANapiData.toUpperCase().includes(data.toUpperCase()) : false} />
                </React.Fragment>
                : false}
        </td> : <td style={{ width: '10%', border: "1px solid #909090" }}></td>}
        {/* {statusAction ? statusAction : "-"}  */}
    </tr>
}


const isAccepted = (docStatus) => {
    return parseInt(docStatus, 10) === documentStatus.ACCEPTED
}


const AadharRejectRender = ({ handleAadharReject }) => {
    return <Button onClick={handleAadharReject} className='bg-danger btn-sm' >Reject Aadhar</Button>
}

class CompanyDetail extends Component {
    constructor(props) {
        super(props)

        this.state = {
            activeTab: TABS.NOT_VERIFIED,
            is_gst_update_modal_open: false,
            gst_no: '',
            errors_gst: ''
        }

        this.getData = this.getData.bind(this)
        this.goBack = this.goBack.bind(this)
        this.fetchAndVerify = this.fetchAndVerify.bind(this)
        this.handleAadharReject = this.handleAadharReject.bind(this)
        this.toggle = this.toggle.bind(this)
        this.onSaveGstUpdate = this.onSaveGstUpdate.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount() {

        const { dispatch } = this.props;
        dispatch({ type: verificationConstants.GET_COMPANY_DETAIL_RESET });

        this.getData();
    }

    handleAadharReject() {
        swal({
            title: "Are you sure want to Reject Aadhar?",
            text: "",
            icon: "warning",
            buttons: true,
            dangerMode: true,
            buttons: ["Cancel", "Yes, I am sure!"],
        }).then((confirm) => {
            if (confirm) {
                const { dispatch, profile: { individuals } } = this.props;
                dispatch(verificationActions.aadharReject({ individual_reputeid: individuals[0].repute_id }, {
                    success: () => {
                        setTimeout(() => {
                            this.getData()
                        }, 2000)
                    }
                }));
            }
        });
    }

    getData() {
        const { dispatch, match: { params } } = this.props;
        dispatch(verificationActions.getCompanyDetail({ reputeId: params.reputeId }));
    }
    
    goBack() {
        this.props.history.goBack();
    }

    fetchAndVerify() {
        let _this = this
        swal({
            title: "Are you sure ?",
            text: "",
            icon: "warning",
            buttons: true,
            dangerMode: true,
            buttons: ["Cancel", "Yes, I am sure!"],
        }).then((confirm) => {
            if (confirm) {
                const { dispatch, match: { params } } = this.props;
                dispatch(verificationActions.fetchAndVerify({ company_reputeid: params.reputeId }, {
                    success: () => {
                        setTimeout(() => {
                            _this.getData()
                        }, 2000)
                    }
                }));
            }
        });
    }

    toggle() {

        const { profile } = this.props
        let gst = profile && profile.gst

        this.setState({
            ...this.state, is_gst_update_modal_open: !this.state.is_gst_update_modal_open, gst_no: gst
        })

    }

    handleChange(event) {
        let { value, name } = event.target
        let errors_gst = ''
        if (name === 'gst_no') {
            value = value.toUpperCase()
            value.length === 15 ? errors_gst = "" : errors_gst = 'Please Enter Valid GST Number.'
        }
        this.setState({
            ...this.state, [name]: value , errors_gst: errors_gst
        })
    }

    onSaveGstUpdate() {
        const { gst_no } = this.state

        if (gst_no.length !== 15) {
            return false
        }
        const { dispatch, profile: { company_reputeid } } = this.props;
        let dataToSend = {
            repute_id: company_reputeid,
            gst: this.state.gst_no,
        }
        dispatch(verificationActions.saveGstUpdate(dataToSend, this));

        this.setState({
            ...this.state, is_gst_update_modal_open: false, errors_gst: false
        })

    }

    render() {
        const {
            profile,
            loading
        } = this.props;

        let gstdata = profile && profile.hasOwnProperty('gstdata') ? profile.gstdata : false;
        let pandata = profile && profile.hasOwnProperty('pandata') ? profile.pandata : false;
        let aadharDetails = profile && profile.hasOwnProperty('individuals') ? profile.individuals[0].aadharDetails
            : false;
        return (
            <div className="">

                <Translate>
                    {({ translate }) =>
                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardHeader>
                                        <i className="fa fa-building"></i><strong>Company Detail </strong>

                                        <Button className='pull-right' onClick={this.goBack}>
                                            Go Back
                                        </Button>
                                        <Button className='pull-right mx-4 bg-success' onClick={this.fetchAndVerify}>
                                            Auto Verify
                                        </Button>
                                    </CardHeader>
                                    <CardBody>

                                        {loading ? <TableLoader /> : <React.Fragment>
                                            {profile ? <div className='table-responsive'>

                                                <table className='table table-striped table-hover '>
                                                    <thead><EachRowData
                                                        data={<b>User Data</b>}
                                                        aadharApidata={<b>Aadhar Details</b>}
                                                        apidata={<b>GST Details </b>}
                                                        // <small><i>(gst required for api data)</i></small>
                                                        PANapiData={<b>PAN Details</b>}
                                                        action={<b>Action</b>}
                                                        statusAction={<b>Status</b>}
                                                    /></thead>
                                                    <tbody>

                                                        <EachRowData
                                                            title={"Repute ID"}
                                                            data={profile.formated_repute_id}
                                                        />
                                                        <EachRowData
                                                            title={"Aadhar Number"}
                                                            aadharApidata={aadharDetails && aadharDetails.aadhaar_number}
                                                            data={aadharDetails && aadharDetails.aadhaar_number}
                                                            status={aadharDetails && aadharDetails.aadhaar_number ? getStatusIcon(3) : ""}
                                                            action={<AadharRejectRender handleAadharReject={this.handleAadharReject} />}
                                                            PANapiData={pandata.masked_aadhaar_number}

                                                        />
                                                        {/* <EachRowData
                                                            title={"Invidiual Name"}
                                                            data={profile.formated_repute_id}
                                                        /> */}



                                                        <EachRowData
                                                            title={"Customer Name"}
                                                            data={profile.individuals[0].name}
                                                            apidata={gstdata.directors}
                                                            aadharApidata={aadharDetails && aadharDetails.name}
                                                            status={getStatusIcon(profile.name_status, profile.rejected_reasons.NAME_VERIFICATION)}
                                                            action={<VerifyDoucment status={isAccepted(profile.name_status) ? documentStatus.ACCEPTED : documentStatus.INREVIEW} repute_id={profile.formated_repute_id} document_type={"NAME_VERIFICATION"} is_cheque_verified={profile.is_cheque_verified} />}
                                                            PANapiData={pandata.category === 'P' ? pandata.name : ""}
                                                        />
                                                        <EachRowData
                                                            title={"Company Name"}
                                                            data={profile.company_name}
                                                            apidata={gstdata.trade_name}
                                                            status={getStatusIcon(profile.name_status, profile.rejected_reasons.NAME_VERIFICATION)}
                                                            PANapiData={pandata.category !== 'P' ? pandata.name : ""}
                                                            action={<VerifyDoucment status={isAccepted(profile.name_status) ? documentStatus.ACCEPTED : documentStatus.INREVIEW} repute_id={profile.formated_repute_id} document_type={"NAME_VERIFICATION"} is_cheque_verified={profile.is_cheque_verified} />}
                                                        />
                                                        <EachRowData
                                                            title={"GSTIN"}
                                                            data={profile.gst}
                                                            apidata={gstdata.gstin}
                                                            status={getStatusIcon(profile.gst_status, profile.rejected_reasons.GSTIN)}
                                                            action={<React.Fragment>
                                                                <VerifyDoucment
                                                                    status={isAccepted(profile.gst_status) ? documentStatus.ACCEPTED : documentStatus.INREVIEW}
                                                                    repute_id={profile.formated_repute_id} document_type={"GSTIN"} is_cheque_verified={profile.is_cheque_verified}
                                                                />
                                                                <UpdateGst
                                                                    is_gst_update_modal_open={this.state.is_gst_update_modal_open}
                                                                    handleChange={this.handleChange}
                                                                    gst_no={this.state.gst_no}
                                                                    onSaveGstUpdate={this.onSaveGstUpdate}
                                                                    toggle={this.toggle}
                                                                    errors_gst={this.state.errors_gst}
                                                                />
                                                            </React.Fragment>
                                                            }
                                                        />

                                                        <EachRowData
                                                            title={"PAN"}
                                                            apidata={gstdata.pan}
                                                            data={profile.pan_number}
                                                            status={getStatusIcon(profile.pan_status, profile.rejected_reasons.PAN_CARD)}
                                                            action={<VerifyDoucment status={isAccepted(profile.pan_status) ? documentStatus.ACCEPTED : documentStatus.INREVIEW} repute_id={profile.formated_repute_id} document_type={"PAN_CARD"} is_cheque_verified={profile.is_cheque_verified} />}
                                                            PANapiData={gstdata.pan}
                                                        />
                                                        <EachRowData
                                                            title={"Mobile Number"}
                                                            // aadharApidata={aadharDetails && aadharDetails.mobile}
                                                            apidata={gstdata.mobile}
                                                            data={profile.individuals[0].mobile}
                                                            status={getStatusIcon(profile.pan_status, profile.rejected_reasons.PAN_CARD)}
                                                            action={<VerifyDoucment status={isAccepted(profile.pan_status) ? documentStatus.ACCEPTED : documentStatus.INREVIEW} repute_id={profile.formated_repute_id} document_type={"PAN_CARD"} is_cheque_verified={profile.is_cheque_verified} />}
                                                        />
                                                        <EachRowData
                                                            title={"Address"}
                                                            aadharApidata={
                                                                aadharDetails && aadharDetails.aadhaar_number ? <Address address={{
                                                                    line1: aadharDetails && aadharDetails.house,
                                                                    line2: aadharDetails && (aadharDetails.street + " " + aadharDetails.landmark + ' ' + aadharDetails.locality + ' ' + aadharDetails.post_office_name),
                                                                    city: aadharDetails && (aadharDetails.sub_district + ' ' + aadharDetails.district),
                                                                    state: aadharDetails && aadharDetails.state,
                                                                    pincode: aadharDetails && aadharDetails.pincode,
                                                                }} /> : ""
                                                            }
                                                            apidata={gstdata.address}
                                                            data={<div className="pull-left" style={{ width: "90%" }}><Address address={profile.address} /> </div>}
                                                            status={
                                                                // pull - right
                                                                <div className="">
                                                                    {getStatusIcon(profile.address_status, profile.rejected_reasons.ADDRESS_COMPANY)}
                                                                </div>

                                                            }
                                                            PANapiData={
                                                                <Address address={{
                                                                    line1: pandata.line_1,
                                                                    line2: pandata.line_2 + " " + pandata.street,
                                                                    city: pandata.city,
                                                                    state: pandata.state,
                                                                    pincode: pandata.pincode,
                                                                }} />
                                                            }
                                                            action={<VerifyDoucment status={documentStatus.ACCEPTED === profile.address_status ? documentStatus.ACCEPTED : documentStatus.INREVIEW} repute_id={profile.formated_repute_id} document_type={"ADDRESS_COMPANY"} is_cheque_verified={profile.is_cheque_verified} />}
                                                        />
                                                        <EachRowData
                                                            title={"Constitution Business / PAN Type"}
                                                            data={"-"}
                                                            apidata={gstdata.constitution_of_business}
                                                            PANapiData={<PanCardCompanyType data={pandata.category} />}
                                                        />
                                                         <EachRowData
                                                            title={"GST/PAN Status"}
                                                            data={"-"}
                                                            apidata={<labal className={"badge " + ( gstdata.status === "Active"? "badge-success" : "badge-danger")} style={{fontSize:"14px"}}> {gstdata.status} </labal>}
                                                            PANapiData={<PanCardCompanyType data={pandata.status} />}
                                                        />
                                                        <EachRowData
                                                            // aadharApidata={aadharDetails && aadharDetails.email}
                                                            title={"Email"}
                                                            data={"-"}
                                                            apidata={gstdata.email}
                                                            PANapiData={pandata.email}
                                                        />
                                                        <EachRowData
                                                            title={"Registered Date and Time"}
                                                            data={profile.created_at}
                                                            apidata={gstdata.date_of_registration}
                                                            PANapiData={pandata.date_of_birth}
                                                        />

                                                    </tbody>
                                                </table>

                                            </div>
                                                : ""}

                                        </React.Fragment>}



                                        <h4>Individuals</h4>

                                        <Row>
                                            {profile && profile.individuals ?
                                                profile.individuals.map((each) => {
                                                    return <Col md="4" >
                                                        <div style={{ 'borderRadius': '10px', "border": '1px solid #c2c2c2', padding: 20 }}>
                                                            <h5>
                                                                {each.name}
                                                            </h5>

                                                            <small>({each.repute_id_formatted})</small>
                                                            <Link to={'/verification/individual/' + each.repute_id_formatted} className='btn btn-primary btn-block mt-4'>
                                                                View Profile
                                                            </Link>
                                                        </div>
                                                    </Col>
                                                }) : false}
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>

                        </Row>
                    }
                </Translate>

                <Documents profile={profile} />
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { profile, loading } = state.companyDetail;
    return {
        profile,
        loading
    }
}

const connectedCompany = connect(mapStateToProps)(CompanyDetail)
export { connectedCompany as CompanyDetail }

